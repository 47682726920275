import React, { useEffect } from "react"
import { navigate } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"
// import LoginForm from "../components/Forms/LoginForm"

const LoginPage = () => {
  useEffect(() => {
    navigate("/");
  }, []);

  return (
    <Layout location="login">
      <SEO title="Login" />

      {/* <h1>Login</h1> */}
      {/* <LoginForm /> */}
    </Layout>
  );
}

export default LoginPage
